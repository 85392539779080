<template>
  <div
    id="outer-wrapper"
  >
    <vue-headful
      :title="`${title} | Octoa`"
    />
    <v-inner-header
      :redirect="redirect"
      @click="onClose"
    />
    <div class="container mx-auto px-4">
      <div class="flex -mx-2 lead-form-edit-container mb-10">
        <div class="w-full px-2 mt-16">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalishSkeleton',
  props: {
    title: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: '/templates'
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>
