<template>
  <modalish-skeleton
    title="New Workflow"
    redirect="/templates/workflows"
  >
    <h1 class="font-bold text-2xl mb-4">
      Create new workflow
    </h1>
    <v-card class="px-6 py-8">
      <div class="font-bold text-base mb-8">
        Workflow settings
      </div>
      <div class="md:flex items-center mb-8">
        <div class="mb-2 md:mb-0 whitespace-no-wrap">
          Choose a workflow name
        </div>
        <div class="md:mx-12 lg:mx-24 flex-grow">
          <v-input v-model="name" placeholder="E.g. Destination Photography" />
        </div>
      </div>
      <div class="md:flex items-center">
        <div class="mb-2 md:mb-0 whitespace-no-wrap">
          Choose a workflow color
        </div>
        <div class="md:mx-12 lg:mx-24 flex-grow">
          <v-alt-color-picker v-model="color" />
        </div>
      </div>
    </v-card>
    <div class="flex justify-end mt-4">
      <button
        class="mr-4 grey-btn"
        @click="$router.back()"
      >
        Cancel
      </button>

      <button
        v-if="$route.meta.mode === 'create'"
        class="green-btn"
        @click="create"
      >
        Start creating
      </button>

      <button
        v-if="$route.meta.mode === 'edit'"
        class="green-btn"
        @click="update"
      >
        Update
      </button>
    </div>
  </modalish-skeleton>
</template>

<script>
import ModalishSkeleton from './Skeletons/ModalishSkeleton'
import VCard from '../../components/VCard/VCard'
import VInput from '../../components/VInput/VInput'
import VAltColorPicker from '../../components/VAltColorPicker/VAltColorPicker'
import { workflowColors } from '../../utils/workflows'
import { createWorkflow, getWorkflow, updateWorkflow } from '../../api/Workflows'

export default {
  name: 'TemplatesCreateWorkflow',
  components: { VAltColorPicker, VInput, VCard, ModalishSkeleton },
  data() {
    return {
      name: '',
      color: workflowColors[0],
      workflow: null
    }
  },
  async mounted () {
    if (this.$route.meta.mode !== 'edit') return

    const res = this.handleErrors(await getWorkflow(this.$route.params.workflowId))
    if (!res) return

    this.workflow = res.data.data
    this.name = this.workflow.name
    this.color = this.workflow.color
  },
  methods: {
    goToManage() {
      this.$router.push(`/templates/workflows/${this.workflow.id}/manage`)
    },
    async create() {
      const res = this.handleErrors(
        await createWorkflow({ name: this.name, color: this.color })
      )

      if (!res) return

      this.workflow = res.data
      this.goToManage()
    },
    async update() {
      const res = this.handleErrors(
        await updateWorkflow(this.workflow.id, { name: this.name, color: this.color })
      )

      if (!res) return

      this.goToManage()
    }
  }
}
</script>

<style scoped>

</style>
